<template>
  <div>
    <div class="container">
      <InfoBox
        v-if="isPrefilledOfferFlow"
        variant="info"
        size="small"
      >
        Skontrolujte aktuálnosť údajov.
      </InfoBox>

      <FormGroup
        label="Ste držiteľom vozidla?"
        tooltipText="Držiteľ je ten, kto je zapísaný v dokladoch vozidla ako držiteľ osvedčenia."
        :rules="[
          'requiredOption',
          $v.rules.not({ compare: false, message: 'Vozidlo môžete poistiť online len vtedy, ak ste zapísaný ako držiteľ osvedčenia v jeho dokladoch. V opačnom prípade sa, prosím, obráťte na naše kontaktné centrum – 02/38 11 11 16.' }),
        ]"
      >
        <InputOptions
          v-model="customer.isVehicleHolder"
          :options="optionsVehicleHolder"
        />
      </FormGroup>

      <InputParty
        :showTypeOptions="false"
        :currentBirthYear="customer.birthYear"
        v-model="$store.state.calculation.customer"
      />
    </div>

    <BaseHeading
      ref="residenceAddress"
      size="small"
    >
      <span v-if="customer.type === 'PERSON'">Adresa trvalého pobytu</span>
      <span v-else>Adresa sídla</span>
    </BaseHeading>

    <div class="container">
      <InputAddress
        v-model="customer.address"
      />
      <BaseButton
        v-if="!showCorrespondenceAddress"
        size="small"
        variant="secondary"
        :style="{ marginBottom: '30px' }"
        @click="addCorrespondenceAddress()"
      >
        Odlišná korešpondenčná adresa
      </BaseButton>
    </div>

    <div v-if="showCorrespondenceAddress" ref="correspondenceAddress">
      <BaseHeading size="small">
        Korešpondenčná adresa
      </BaseHeading>
      <div class="container">
        <InputAddress
          v-model="customer.correspondenceAddress"
        />
        <BaseButton
          size="small"
          variant="secondary"
          :style="{ marginBottom: '30px' }"
          @click="removeCorrespondenceAddress()"
        >
          Vymazať korešpondenčnú adresu
        </BaseButton>
      </div>
    </div>

    <BaseHeading size="small" id="kontakt">
      Skontrolujte vaše kontaktné údaje
    </BaseHeading>
    <div class="container">
      <InputContact
        v-model="$store.state.calculation.customer"
        isBeforeConfirmation
      />
    </div>

  </div>
</template>

<script>
import scrollToElement from '@gds/util/scrollToElement';

import BaseHeading from '@gds/components/BaseHeading';
import BaseButton from '@gds/components/BaseButton';
import InputOptions from '@gds/components/InputOptions';
import InputParty from '@gds/common/calculators/InputParty';
import InputAddress from '@gds/common/calculators/InputAddress';
import InputContact from '@gds/common/calculators/InputContact';
import FormGroup from '@gds/components/FormGroup';
import InfoBox from '@gds/components/InfoBox';

import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showCorrespondenceAddress: false,
    };
  },
  components: {
    BaseHeading,
    BaseButton,
    InputOptions,
    InputParty,
    InputAddress,
    InputContact,
    FormGroup,
    InfoBox,
  },
  computed: {
    ...mapGetters([
      'customer',
      'isPrefilledOfferFlow',
    ]),
    optionsVehicleHolder: () => [
      { value: true, label: 'Áno' },
      { value: false, label: 'Nie' },
    ],
  },
  methods: {
    setShowCorresondenceAddress() {
      this.showCorrespondenceAddress = !!this.customer.correspondenceAddress.postalCode;
    },
    addCorrespondenceAddress() {
      this.showCorrespondenceAddress = true;
      this.$nextTick(() => {
        scrollToElement({ element: this.$refs.correspondenceAddress });
      });
    },
    removeCorrespondenceAddress() {
      scrollToElement({ element: this.$refs.residenceAddress.$el });
      setTimeout(() => {
        this.showCorrespondenceAddress = false;
        this.clearCorrespondenceAddress();
      }, 500);
    },
    clearCorrespondenceAddress() {
      const { correspondenceAddress } = this.customer;
      Object.keys(correspondenceAddress).forEach((key) => {
        correspondenceAddress[key] = '';
      });
    },
  },
  created() {
    this.$store.dispatch('update', {
      apollo: this.$apollo,
    });
  },
  mounted() {
    this.$sendToCI();
    this.setShowCorresondenceAddress();
  },
};
</script>
